var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container container--md-50 pt-0" },
    [
      _vm.isAllowedToViewDialog
        ? _c("AllowedToView", {
            attrs: { report: _vm.reportModel, editor: "planning" },
            on: { handleAlert: _vm.allowedToView }
          })
        : _vm._e(),
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", row: "" } },
            [
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c(
                  "div",
                  { staticClass: "elementPanel elementPanel__sub" },
                  [
                    _vm.isLoading
                      ? _c("LoaderCard", {
                          attrs: {
                            flat: "",
                            type: "spinner--center",
                            minHeight: "300px"
                          }
                        })
                      : _vm._e(),
                    !_vm.isLoading
                      ? [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", [
                                _c("h2", { staticClass: "sectionTitle" }, [
                                  _vm._v(
                                    "Expertisebureau, Opnemer en Deskundige"
                                  )
                                ])
                              ]),
                              _c(
                                "v-flex",
                                { staticClass: "text-right" },
                                [
                                  _c("v-checkbox", {
                                    staticClass:
                                      "planningByOrganisation-checkbox",
                                    attrs: {
                                      label: "Planning door Expertisebureau",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.planningByOrganization,
                                      callback: function($$v) {
                                        _vm.planningByOrganization = $$v
                                      },
                                      expression: "planningByOrganization"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.organizations,
                                      label: "Expertisebureau",
                                      "item-text": "name",
                                      "item-value": "id"
                                    },
                                    model: {
                                      value: _vm.selectedOrganization,
                                      callback: function($$v) {
                                        _vm.selectedOrganization = $$v
                                      },
                                      expression: "selectedOrganization"
                                    }
                                  })
                                ],
                                1
                              ),
                              !_vm.planningByOrganization
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "field",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.experts,
                                          label: "Opnemer",
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          "hide-details": "",
                                          clearable: "",
                                          disabled: !_vm.selectedOrganization
                                            .length
                                        },
                                        model: {
                                          value: _vm.selectedExpert,
                                          callback: function($$v) {
                                            _vm.selectedExpert = $$v
                                          },
                                          expression: "selectedExpert"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.planningByOrganization
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "field",
                                      attrs: { xs12: "" }
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.experts,
                                          label:
                                            "[OPTIONEEL] Deskundige (indien afwijkt van de opnemer)",
                                          "item-text": "name",
                                          "item-value": "uuid",
                                          "hide-details": "",
                                          clearable: "",
                                          disabled:
                                            !_vm.selectedOrganization.length &&
                                            !_vm.selectedExpert.length
                                        },
                                        model: {
                                          value: _vm.selectedSecondExpert,
                                          callback: function($$v) {
                                            _vm.selectedSecondExpert = $$v
                                          },
                                          expression: "selectedSecondExpert"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          !_vm.planningByOrganization
                            ? [
                                _c("h2", { staticClass: "sectionTitle" }, [
                                  _vm._v("Datum en Tijd")
                                ]),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        ref: "dialog",
                                        attrs: {
                                          "close-on-content-click": false,
                                          lazy: "",
                                          "nudge-right": "130",
                                          "full-width": "",
                                          "max-width": "290px",
                                          "min-width": "290px"
                                        },
                                        model: {
                                          value: _vm.isEditingPlannedAt,
                                          callback: function($$v) {
                                            _vm.isEditingPlannedAt = $$v
                                          },
                                          expression: "isEditingPlannedAt"
                                        }
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "input input-date",
                                          attrs: {
                                            slot: "activator",
                                            "prepend-icon": "date_range",
                                            placeholder: "DD-MM-JJJJ",
                                            label: "Inplannen op",
                                            "error-messages":
                                              _vm.plannedAtDateErrorMessage
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.plannedAtDateFromTextField(
                                                $event
                                              )
                                            }
                                          },
                                          slot: "activator",
                                          model: {
                                            value: _vm.plannedAtDateFormatted,
                                            callback: function($$v) {
                                              _vm.plannedAtDateFormatted = $$v
                                            },
                                            expression: "plannedAtDateFormatted"
                                          }
                                        }),
                                        _c("v-date-picker", {
                                          ref: "picker",
                                          attrs: {
                                            locale: "nl-nl",
                                            min: "1910-01-01",
                                            color: "#837f16",
                                            "first-day-of-week": "1"
                                          },
                                          on: {
                                            change: function($event) {
                                              _vm.isEditingPlannedAt = false
                                            }
                                          },
                                          model: {
                                            value: _vm.planned_at_date,
                                            callback: function($$v) {
                                              _vm.planned_at_date = $$v
                                            },
                                            expression: "planned_at_date"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "input input-time",
                                      attrs: {
                                        slot: "activator",
                                        mask: _vm.mask.time,
                                        label: "Inplannen op (tijd)",
                                        "prepend-icon": "access_time",
                                        placeholder: "13:00"
                                      },
                                      slot: "activator",
                                      model: {
                                        value: _vm.planned_at_time,
                                        callback: function($$v) {
                                          _vm.planned_at_time = $$v
                                        },
                                        expression: "planned_at_time"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("h2", { staticClass: "sectionTitle" }, [
                                _vm._v("Algemene opmerking")
                              ]),
                              _c("v-textarea", {
                                attrs: {
                                  label:
                                    "Schadeomschrijving na contact met bewoner"
                                },
                                model: {
                                  value: _vm.damageDescription,
                                  callback: function($$v) {
                                    _vm.damageDescription = $$v
                                  },
                                  expression: "damageDescription"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("h2", { staticClass: "sectionTitle" }, [
                                _vm._v("Tags")
                              ]),
                              _c("ReportTags", {
                                attrs: {
                                  reportId: _vm.reportId,
                                  reportTags: _vm.reportModel.tags
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _c("v-flex", { attrs: { xs6: "" } }, [
                _c(
                  "div",
                  { staticClass: "elementPanel elementPanel__sub" },
                  [
                    _vm.isLoading
                      ? _c("LoaderCard", {
                          attrs: {
                            flat: "",
                            type: "spinner--center",
                            minHeight: "300px"
                          }
                        })
                      : _vm._e(),
                    !_vm.isLoading
                      ? [
                          _c(
                            "v-layout",
                            [
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _c("h2", { staticClass: "sectionTitle" }, [
                                  _vm._v("Werkvoorbereiding")
                                ])
                              ]),
                              _c(
                                "v-flex",
                                { attrs: { xs5: "", "text-xs-right": "" } },
                                [
                                  _c(
                                    "MiButton",
                                    {
                                      attrs: {
                                        color: "primary",
                                        to:
                                          "/work-preparation/" +
                                          _vm.reportModel.uuid,
                                        round: "true",
                                        outline: "true",
                                        background: "true"
                                      }
                                    },
                                    [_vm._v(" Start WV editor ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              staticClass: "item__list item__list--reports",
                              attrs: { wrap: "" }
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "list__item",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [_vm._v("Schade nummer:")])
                                      ]),
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.reportModel.case_number)
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "list__item",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [_vm._v("Naam aanvrager:")])
                                      ]),
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.reportModel.applicant
                                                ? _vm.reportModel.applicant.name
                                                : ""
                                            )
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "list__item",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [
                                          _vm._v("Schadeadres straat:")
                                        ])
                                      ]),
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.generalKeys.SchadeadresStraat
                                                .value
                                            )
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "list__item",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [
                                          _vm._v("Schadeadres huisnummer:")
                                        ])
                                      ]),
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.generalKeys
                                                  .SchadeadresHuisnummer.value
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.generalKeys
                                                  .SchadeadresHuisnummerToevoeging
                                                  .value
                                              ) +
                                              " "
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "list__item",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [_vm._v("Postcode:")])
                                      ]),
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.generalKeys
                                                .SchadeadresPostcode.value
                                            )
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "list__item",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [_vm._v("Plaats:")])
                                      ]),
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.generalKeys.SchadeadresPlaats
                                                .value
                                            )
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "list__item",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [_vm._v("Eigendom sinds:")])
                                      ]),
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                _vm.generalKeys.EigendomSinds
                                                  .value
                                              )
                                            )
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "list__item",
                                  attrs: { xs12: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", "align-center": "" } },
                                    [
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [_vm._v("Bouwjaar:")])
                                      ]),
                                      _c("v-flex", { attrs: { xs6: "" } }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.generalKeys.BouwJaar.value
                                            )
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.reportModel.validations
                            ? _c(
                                "v-layout",
                                {
                                  staticClass: "item__list item__list--reports",
                                  attrs: { wrap: "", "mt-3": "" }
                                },
                                [
                                  _vm._l(_vm.reportModel.validations, function(
                                    validation,
                                    key
                                  ) {
                                    return [
                                      validation !== "ok"
                                        ? _c(
                                            "v-flex",
                                            {
                                              key: key,
                                              staticClass: "list__item",
                                              attrs: { xs12: "" }
                                            },
                                            [
                                              _c("ValidationWarning", {
                                                attrs: {
                                                  validation: validation,
                                                  validationKey: key
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "h2",
                            {
                              staticClass:
                                "sectionTitle sectionTitle--marginTop"
                            },
                            [_vm._v("Historische schades")]
                          ),
                          !_vm.historicalReports.length
                            ? _c("p", { staticClass: "fadedText" }, [
                                _vm._v("Er zijn geen historische schades")
                              ])
                            : _vm._e(),
                          _vm.historicalReports && _vm.historicalReports.length
                            ? [
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      wrap: "",
                                      "py-3": "",
                                      "align-center": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "text-xs-right handlepanel"
                                      },
                                      [
                                        _vm.isOpenPanels
                                          ? _c("v-flex", [
                                              _c(
                                                "div",
                                                { staticClass: "panel" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click:
                                                          _vm.closeAllHistoricReports
                                                      }
                                                    },
                                                    [
                                                      _vm._v(" sluit alle "),
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "keyboard_arrow_up"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        !_vm.isOpenPanels
                                          ? _c("v-flex", [
                                              _c(
                                                "div",
                                                { staticClass: "panel" },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      on: {
                                                        click:
                                                          _vm.openAllHistoricReports
                                                      }
                                                    },
                                                    [
                                                      _vm._v(" open alle "),
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          "keyboard_arrow_down"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._l(_vm.historicalReports, function(
                                  historicalReport,
                                  index
                                ) {
                                  return _c(
                                    "v-expansion-panel",
                                    {
                                      key: index,
                                      staticClass: "damagePanel",
                                      attrs: { expand: "" },
                                      model: {
                                        value: _vm.panelHistoricalReport[index],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.panelHistoricalReport,
                                            index,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "panelHistoricalReport[index]"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "header" },
                                              slot: "header"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(index + 1) +
                                                  ". " +
                                                  _vm._s(
                                                    historicalReport.name
                                                      ? historicalReport.name
                                                      : "Historische schade " +
                                                          (index + 1)
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-card",
                                            {
                                              staticStyle: {
                                                "border-top": "1px solid #ddd",
                                                "border-bottom":
                                                  "1px solid #ddd"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  historicalReport.answers
                                                    ? _c(
                                                        "v-layout",
                                                        {
                                                          staticClass:
                                                            "item__list",
                                                          attrs: { wrap: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "label",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs10: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Algemeen"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Status:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              historicalReport
                                                                                .answers
                                                                                .historicalReport_Status
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Zelfde eigenaar:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              historicalReport
                                                                                .answers
                                                                                .historicalReport_ZelfdeEigenaar
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Opnamedatum:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.parseDate(
                                                                                historicalReport
                                                                                  .answers
                                                                                  .historicalReport_Opnamedatum
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Verslagdatum:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.parseDate(
                                                                                historicalReport
                                                                                  .answers
                                                                                  .historicalReport_Verslagdatum
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Naam expert:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              historicalReport
                                                                                .answers
                                                                                .historicalReport_Naamexpert
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Calculatie expert:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "currency"
                                                                              )(
                                                                                historicalReport
                                                                                  .answers
                                                                                  .historicalReport_Calculatieexpert
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Datum akkoord:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.parseDate(
                                                                                historicalReport
                                                                                  .answers
                                                                                  .historicalReport_DatumAkkoord
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  historicalReport.answers
                                                    ? _c(
                                                        "v-layout",
                                                        {
                                                          staticClass:
                                                            "item__list item__list--reports",
                                                          attrs: { wrap: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "label",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs10: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Schade"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "ABC:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              historicalReport
                                                                                .answers
                                                                                .historicalReport_ABCSchadesAantallen
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Keuze KUS / KEA / KH:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              historicalReport
                                                                                .answers
                                                                                .historicalReport_KeuzeKUSKEAKHC
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Finale kwijting:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              historicalReport
                                                                                .answers
                                                                                .historicalReport_FinaleKwijting
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Omschrijving:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              historicalReport
                                                                                .answers
                                                                                .historicalReport_OmschrijvingSchadelocatie
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Uitbetaald:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              historicalReport
                                                                                .answers
                                                                                .historicalReport_Uitbetaald
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Bedrag uitbetaling:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "currency"
                                                                                )(
                                                                                  historicalReport
                                                                                    .answers
                                                                                    .historicalReport_BedragUitbetaling
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Nulmeting vereist:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              historicalReport
                                                                                .answers
                                                                                .historicalReport_NulMetingLight
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "list__item",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                    "align-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs7: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Bijzonderheden:"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs5: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              historicalReport
                                                                                .answers
                                                                                .historicalReport_BIJZONDERHEDEN
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  historicalReport.media &&
                                                  historicalReport.media.length
                                                    ? _c(
                                                        "v-layout",
                                                        {
                                                          staticClass:
                                                            "item__list item__list--reports",
                                                          attrs: { wrap: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "label",
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs10: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Bijlage"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._l(
                                                            historicalReport.media,
                                                            function(media, i) {
                                                              return _c(
                                                                "v-flex",
                                                                {
                                                                  key: i,
                                                                  staticClass:
                                                                    "list__item",
                                                                  attrs: {
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-layout",
                                                                    {
                                                                      attrs: {
                                                                        wrap:
                                                                          "",
                                                                        "align-center":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs10:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "item__name item__link"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  attrs: {
                                                                                    href:
                                                                                      media.original,
                                                                                    target:
                                                                                      "_blank"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        media.file_name
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-flex",
                                                                        {
                                                                          staticClass:
                                                                            "text-xs-right",
                                                                          attrs: {
                                                                            xs2:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "item__icon item__icon--action"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "a",
                                                                                {
                                                                                  attrs: {
                                                                                    href:
                                                                                      media.original,
                                                                                    target:
                                                                                      "_blank"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "cloud_download"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                })
                              ]
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("ApprovalBar", {
        attrs: { backUrl: "/reports/" + _vm.reportModel.uuid },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "actionContainer" },
                  [
                    _c(
                      "MiButton",
                      {
                        attrs: {
                          color: "primary",
                          small: "true",
                          icon: "undo"
                        },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openApprovalDialog(
                              "created",
                              "Terugzetten naar:"
                            )
                          }
                        }
                      },
                      [_vm._v(" Terugzetten naar: Werkvoorbereiding ")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "actionContainer" },
                  [
                    _c(
                      "MiButton",
                      {
                        attrs: { color: "alert", small: "true", icon: "send" },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openRequestDialog($event)
                          }
                        }
                      },
                      [_vm._v("Uitvraag")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "actionContainer" },
                  [
                    _c(
                      "MiButton",
                      {
                        attrs: {
                          color: "success",
                          small: "true",
                          icon: "check_circle",
                          disabled: _vm.isDisabled
                        },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.openApprovalDialog($event)
                          }
                        }
                      },
                      [_vm._v(" Verstuur naar Expertisebureau ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.requestDialogOpen
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.requestDialogOpen,
                callback: function($$v) {
                  _vm.requestDialogOpen = $$v
                },
                expression: "requestDialogOpen"
              }
            },
            [
              _c("RequestDialog", {
                attrs: { report: _vm.reportModel, title: "Uitvraag planning" },
                model: {
                  value: _vm.requestDialogOpen,
                  callback: function($$v) {
                    _vm.requestDialogOpen = $$v
                  },
                  expression: "requestDialogOpen"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.approvalDialogOpen
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.approvalDialogOpen,
                callback: function($$v) {
                  _vm.approvalDialogOpen = $$v
                },
                expression: "approvalDialogOpen"
              }
            },
            [
              _c("ApprovalDialog", {
                attrs: {
                  report: _vm.reportModel,
                  title: _vm.toTitle,
                  status: _vm.toStatus,
                  organization: _vm.selectedOrganization,
                  expert: _vm.selectedExpert,
                  secondExpert: _vm.selectedSecondExpert,
                  damageDescription: _vm.damageDescription,
                  dateTime: _vm.planned_at
                },
                model: {
                  value: _vm.approvalDialogOpen,
                  callback: function($$v) {
                    _vm.approvalDialogOpen = $$v
                  },
                  expression: "approvalDialogOpen"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }