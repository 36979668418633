import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { isValidDate, setFormattedDatePickerValue, dateErrorMessage, formatDateSeconds } from '@/support/String';
import ErrorHandler from '@/support/ErrorHandler';
import { Report as ReportModel, Answer } from '@/models/Report';
import { User } from '@/models/User';
import { DateTime } from 'luxon';
import { Organization } from '@/models/Organization';
import { Options } from '@/components/mi-dialog/MiDialog';
import { GeneralKey, GeneralKeys } from '@/support/GeneralKeys';
import { HistoricalReport } from '@/models/HistoricalReport';
import { Panel } from '@/views/Reports/Report';
import { parseWarning } from '@/support/Warning';
import RequestDialog from '@/components/dialog/request-dialog/RequestDialog.vue';

@Component<Planning>({
  components: {
    RequestDialog,
  },
  filters: {
    dateFormat: (date: string) => {
      if (! date) { return ''; }
      return formatDateSeconds(date);
    },
  },
})
export default class Planning extends Vue {
  public $pageTitle = 'Planning';

  protected reportModel: ReportModel = new ReportModel();

  protected reportId = '';

  protected isLoading = true;

  protected planned_at = '';

  protected planned_at_date = '';

  protected planned_at_time = '13:00';

  protected isEditingPlannedAt = false;

  protected selectedExpert = '';

  protected selectedSecondExpert = '';

  protected selectedOrganization = '';

  protected organizations: any[] = [];

  protected organizationExperts: any[] = [];

  protected generalKeys: GeneralKey = GeneralKeys;

  protected historicalReports: HistoricalReport[] = [];

  protected panelHistoricalReport: Panel[] | null = [];

  protected isOpenPanels = false;

  protected planningByOrganization = false;

  protected experts: any[] = [];

  protected expertNames: string[] = [];

  protected isSearchingExpert = false;

  protected userFilter: UserFilter = {};

  protected damageDescription = '';

  protected isAllowedToView = false;

  protected isAllowedToViewDialog = false;

  // approval bar
  protected approvalDialogOpen = false;

  protected requestDialogOpen = false;

  protected approvalType = '';

  protected approval = '';

  protected itemTitle ='';

  protected toStatus = 'in_progress';

  protected toTitle = 'Verander naar:';

  protected dateTime = '';

  protected status = false;

  protected visibility: any = {
    edit: true,
    duplicate: false,
  };

  protected mask: Mask = {
    time: '##:##',
  };

  public mounted() {
    this.planned_at = `${this.planned_at_date} ${this.parseTime(this.planned_at_time)}`;
    this.planned_at_date = DateTime.local().toFormat('yyyy-MM-dd');
    this.reportId = this.$route.params.id;
    this.initialize();
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Alle rapporten', path: '/reports' },
          { name: this.reportModel.case_number, path: `/reports/${this.reportModel.uuid}` },
          { name: 'Planning' },
        ],
      });
  }

  protected initialize() {
    this.getOrganizations();
    this.getExperts();
    this.isLoading = true;

    this.reportModel
      .include(['damages', 'answers', 'validations'])
      .find(this.$route.params.id)
      .then((report: ReportModel) => {
        this.reportModel = report;

        this.setPlannedAt();
        this.setGeneralInfo();
        this.fetchHistoricalReports();
        this.initBreadcrumb();
        this.pingReport();

        this.isAllowedToViewDialog = true;
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected pingReport() {
    if (! this.reportModel || ! this.reportModel.uuid) {
      return;
    }

    this.reportModel
      .pingReport(this.reportModel.uuid)
      .then((report: ReportModel) => {
      //
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get dialogOptionsUnauthorized(): Options {
    return {
      title: 'Niet beschikbaar',
      text: 'Deze pagina is op dit moment niet beschikbaar',
      type: 'error',
      buttons: {
        confirm: {
          text: 'Terug naar dossier',
          action: () => {
            this.$router.push(`/reports/${this.reportModel.uuid}`);
          },
        },
      },
    };
  }

  protected fetchHistoricalReports() {
    new HistoricalReport()
      .include(['files'])
      .filter({ report: this.reportModel.uuid })
      .all()
      .then((historicalReports: HistoricalReport[]) => {
        this.historicalReports = historicalReports;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected openAllHistoricReports() {
    this.panelHistoricalReport = [...Array((this.historicalReports as any).length).keys()].map((_) => [true]);
    this.isOpenPanels = true;
  }

  protected closeAllHistoricReports() {
    this.panelHistoricalReport = [];
    this.isOpenPanels = false;
  }

  protected allowedToView(allowed: boolean) {
    this.isAllowedToView = allowed;
  }

  protected getExperts() {
    new User()
      .all()
      .then((users: User[]) => {
        this.experts = users;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getOrganizations() {
    new Organization()
      .all()
      .then((organizations: Organization[]) => {
        this.organizations = organizations.filter((organization: Organization) => organization.type !== 'tcmg');
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getOrganizationExperts() {
    new Organization()
      .include('experts')
      .find(this.selectedOrganization)
      .then((response: Organization) => {
        this.experts = response.experts;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected setPlannedAt() {
    if (this.reportModel.planned_at && this.reportModel.planned_at.length && this.reportModel.planned_at !== '0000-00-00 00:00:00') {
      this.planned_at = this.reportModel.planned_at;
      this.planned_at_date = DateTime.fromFormat(this.reportModel.planned_at, 'yyyy-LL-dd HH:mm:ss').toFormat('yyyy-LL-dd');
      this.planned_at_time = DateTime.fromFormat(this.reportModel.planned_at, 'yyyy-LL-dd HH:mm:ss').toFormat('HH:mm');
    }
  }

  protected setGeneralInfo() {
    const keys = Object.keys(this.generalKeys);
    if (! this.reportModel.answers) {
      return;
    }

    this.reportModel.answers.forEach((anwser: Answer) => {
      if (keys.includes(anwser.key ? anwser.key : '')) {
        (this.generalKeys as any)[anwser.key ? anwser.key : ''].value = anwser.value;
      }
    });
  }

  // DATE
  protected plannedAtDateFormatted: string | null = null;

  protected plannedAtDateErrorMessage = '';

  protected plannedAtDateFromDatePicker() {
    if (this.planned_at_date) {
      this.plannedAtDateFormatted = setFormattedDatePickerValue(this.planned_at_date, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.plannedAtDateErrorMessage = ! isValidDate(this.plannedAtDateFormatted) ? dateErrorMessage : '';
    }
  }

  protected plannedAtDateFromTextField(value: string) {
    this.plannedAtDateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.planned_at_date = setFormattedDatePickerValue(value);
  }

  @Watch('planned_at_date')
  protected dateChanged() {
    this.plannedAtDateFromDatePicker();
  }

  @Watch('isEditingPlannedAt')
  protected isEditingApprovedAtChanged() {
    if (! this.isEditingPlannedAt) {
      this.plannedAtDateFromDatePicker();
    }
  }

  protected parseWarning(alert: string) {
    return parseWarning(alert);
  }

  protected parseDate(date: string) {
    if (! date) {
      return '';
    }
    if (date.length < 12) {
      return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('dd-MM-yyyy');
    }
    return DateTime.fromFormat(date, 'yyyy-MM-dd hh:mm:ss').toFormat('dd-MM-yyyy');
  }

  protected parseTime(time: string) {
    if (time.includes(':')) {
      // eslint-disable-next-line no-self-assign
      time = time;
    } else {
      const timeSplit = time.match(/.{1,2}/g);

      if (timeSplit) {
        time = `${timeSplit[0]}:${timeSplit[1]}`;
      }
    }

    return `${time}:00`;
  }

  private clearSelectedExpert() {
    this.selectedSecondExpert = '';
    this.selectedExpert = '';
  }

  private clearSelectedDateTime() {
    this.planned_at = '';
    this.planned_at_date = '';
    this.planned_at_time = '';
  }

  protected openRequestDialog() {
    this.requestDialogOpen = true;
  }

  protected get isDisabled(): boolean {
    if (this.planningByOrganization && this.selectedOrganization && this.selectedOrganization.length) {
      return false;
    }

    const hasDateError = this.plannedAtDateErrorMessage.length > 0;

    if (this.selectedExpert && this.selectedExpert.length && this.selectedOrganization && this.selectedOrganization.length && ! this.planningByOrganization && ! hasDateError) {
      return false;
    }
    return true;
  }

  protected openApprovalDialog(status = '', title = '') {
    if (this.isDisabled && ! status) {
      return;
    }

    if (this.isOpnameInProgress()) {
      this.toStatus = 'opname_in_progress';
    }

    if (this.reportModel.isStuwmeerRegelingReport) {
      this.toStatus = 'smr_in_progress';
    }

    if (this.planningByOrganization) {
      this.toStatus = 'planning_organization';
    }

    if (status === 'created') {
      this.toStatus = status;
      this.toTitle = title;
    }

    this.approvalDialogOpen = true;
  }

  protected isOpnameInProgress() {
    const expert = this.experts.find((expert: User) => expert.uuid === this.selectedExpert);
    if (expert && expert.is_opnemer) {
      return true;
    }

    if (this.reportModel.isOpnemerReport
        || this.reportModel.isWoningCooperatie2Report
        || this.reportModel.isAannemersReport) {
      return true;
    }

    return false;
  }

  @Watch('selectedOrganization')
  protected selectedOrganizationChanged(to: any, from: any) {
    this.getOrganizationExperts();
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    //
  }

  @Watch('planned_at_date')
  @Watch('planned_at_time')
  public planned_at_dateChanged() {
    this.planned_at = `${this.planned_at_date} ${this.parseTime(this.planned_at_time)}`;
  }

  @Watch('planningByOrganization')
  protected planningByOrganizationChanged() {
    if (this.planningByOrganization) {
      this.clearSelectedDateTime();
      this.clearSelectedExpert();
    } else {
      this.setPlannedAt();
    }
  }
}

interface Mask {
  [key: string]: string;
}

interface UserFilter {
  search?: string;
}
